import { useSettingsContext } from "@/context";
import { fetchLiveRateData } from "@/utils/apiServices";
import { IPDATAAPIKEY } from "@/utils/constant";
import { useState, useEffect } from "react";

const useIpDataAndCurrency = () => {
  const [IsLoading, setIsLoading] = useState<boolean>(false);
  const [isFetch, setIsFetch] = useState<boolean>(false);
  const { onUpdate, liveCurrencyRate, currentCountryCurrency } =
    useSettingsContext();
  const API_URL = "https://api.ipdata.co/?api-key=" + IPDATAAPIKEY;

  useEffect(() => {
    // If the currency rate and country currency data already exist, no need to fetch again
    if (liveCurrencyRate && currentCountryCurrency) return;

    const fetchIpData = async () => {
      setIsLoading(true);
      try {
        // Fetch IP data
        const response = await fetch(API_URL);
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const result = await response.json();
        onUpdate("currentCountryCurrency", result?.currency?.code);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchIpData();
  }, [liveCurrencyRate,currentCountryCurrency]); // Re-run only if context values change
  useEffect(() => {
    const fetchCurrencyData = async () => {
      if (currentCountryCurrency !== "SAR") {
        const currencyTo = currentCountryCurrency;
        if (currencyTo && currencyTo !== "") {
          const liveRateData = await fetchLiveRateData(currencyTo);
          if (liveRateData.success && liveRateData.quotes) {
            const rateKey = `SAR${currencyTo}`;
            // Store the fetched data in the context
            onUpdate("currentCountryCurrency", currencyTo);
            onUpdate("liveCurrencyRate", liveRateData.quotes[rateKey]);
            setIsFetch(true);
          }
        }
      }
    };
    fetchCurrencyData();
  }, [isFetch, currentCountryCurrency]);
  return { IsLoading };
};

export default useIpDataAndCurrency;
